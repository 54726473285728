import { createAction } from 'redux-starter-kit';
import { apiAction } from 'store/actions';
import { filterQueryResolver, ErrorMessageSubscribe } from 'utils';
import { toast } from 'react-toastify';

export const setQuery = createAction('setQuery');
export const setFinance = createAction('setFinance');

export const setQueryCount = createAction('setQueryCount');
export const setBusinessSector = createAction('setBusinessSector');
export const setFeature = createAction('setFeature');
export const setTenantFeatueSub = createAction('setTenantFeatueSub');

export const setTenantFeatue = createAction('setTenantFeatue');
export const setTenantId = createAction('setTenantId');

export const setPayments = createAction('setPayments');

export const setInvoiceId = createAction('setInvoiceId');
export const setHistory = createAction('setHistory');

export const setSubscribeUpdateId = createAction('setSubscribeUpdateId');
export const setSubscribeUpdateIdFinance = createAction(
  'setSubscribeUpdateIdFinance'
);

export const setSubscribeCount = createAction('setSubscribeCount');

export const setDemoDays = createAction('setDemoDays');

const urlProJobsSector =
  process.env.NODE_ENV === 'production'
    ? process.env.REACT_APP_JOBS_API_URL
    : process.env.REACT_APP_DEV_JOBS_API_URL;

export const fetchRegistry = filters => {
  const query = filterQueryResolver(filters);
  const url = `/admin/tenants?${query}`;
  return apiAction({
    url,
    label: 'registryType',
    onSuccess: data => dispatch => {
      dispatch(setQuery(data));
      dispatch(fetchSector(data));
      dispatch(getTotalCount({ query }));
      dispatch(fetchFeatureAdmin(data));
      dispatch(fetchDemoDays(data));
    },
    attribute: {},
  });
};

export const fetchDemoDays = () =>
  apiAction({
    url: 'admin/tenants/count/grouped-by-demo-days',
    onSuccess: setDemoDays,
    label: 'registryType',
  });

export const fetchTenentFeatureSub = filters => {
  const query = filterQueryResolver(filters);
  const url = `/admin/tenant-subscriptions?${query}`;
  return apiAction({
    url,
    label: 'registryType',
    onSuccess: data => dispatch => {
      dispatch(setFinance(data));
      dispatch(getSuscribeTotalCount({ query }));
    },
    attribute: {},
  });
};

export function getSuscribeTotalCount({ query }) {
  const url = `/admin/tenant-subscriptions/count?${query}`;
  return apiAction({
    url,
    label: 'registryType',
    onSuccess: setSubscribeCount,
    attribute: {},
  });
}

export const fetchTenantId = id =>
  apiAction({
    url: `/admin/tenants/${id}`,
    onSuccess: setTenantId,
    label: 'fetchTenantId',
  });

export function getTotalCount({ query }) {
  const url = `/admin/tenants/count?${query}`;
  return apiAction({
    url,
    label: 'registryType',
    onSuccess: setQueryCount,
    attribute: {},
  });
}

export const fetchSector = () =>
  apiAction({
    url: `${urlProJobsSector}/sectors`,
    onSuccess: setBusinessSector,
    label: 'registryType',
  });
export const fetchFeatureAdmin = () =>
  apiAction({
    url: '/admin/features',
    onSuccess: setFeature,
    label: 'registryType',
  });

export const fetchTenentFeature = filters => {
  const query = filterQueryResolver(filters);
  const url = `/admin/tenant-features?${query}`;
  return apiAction({
    url,
    label: 'registryType',
    onSuccess: setTenantFeatue,
    attribute: {},
  });
};
/**
 *
 * @param {Object} data
 * @param {Function} callback
 */

// Demo Query Modal / create
export function createQueryDemo(data, callback, onFailure = () => {}) {
  return apiAction({
    url: '/admin/tenant-features',
    method: 'POST',
    onFailure,
    data,
    onSuccess: callback,
    label: 'action',
  });
}
export function updateDemoStatus(data, callback, onFailure = () => {}) {
  return apiAction({
    url: '/admin/tenants/status/2',
    method: 'POST',
    onFailure,
    data,
    onSuccess: callback,
    label: 'action',
  });
}
// Subscriber Query Modal / create
export function createQuerySubscriber(data, callback, onFailure = () => {}) {
  return apiAction({
    url: '/admin/tenant-subscriptions',
    method: 'POST',
    onFailure: error => () => {
      const message = ErrorMessageSubscribe(error);

      toast.error(message);
    },
    data,
    onSuccess: callback,
    label: 'action',
  });
}
// Reject Query Modal
export function queryReject(data, callback, onFailure = () => {}) {
  return apiAction({
    url: '/admin/tenants/status/4',
    method: 'POST',
    onFailure,
    data,
    onSuccess: callback,
    label: 'action',
  });
}
export function activateSuspended(id=3,data,callback, onFailure = () => {}) {
  return apiAction({
    url: `/admin/tenants/status/${id}`,
    method: 'POST',
    onFailure,
    data,
    onSuccess: callback,
    label: 'action',
  });
}
// Termination Query Modal
export function queryTermination(data, callback, onFailure = () => {}) {
  return apiAction({
    url: '/admin/tenants/status/termination',
    method: 'POST',
    onFailure,
    data,
    onSuccess: callback,
    label: 'action',
  });
}

// Reject Tab- PaymentsTab
export const fetchPayments = filters => {
  const query = filterQueryResolver(filters);
  const url = `/admin/tenant-subscriptions?${query}`;
  return apiAction({
    url,
    label: 'registryType',
    onSuccess: setPayments,
    attribute: {},
  });
};

// History tab
export const fetchHistory = filters => {
  const query = filterQueryResolver(filters);
  const url = `/admin/tenant-history?${query}`;
  return apiAction({
    url,
    label: 'registryType',
    onSuccess: setHistory,
    attribute: {},
  });
};
// ------------

// Reject Query Modal
export function getPayment(data, callback, onFailure = () => {}) {
  return apiAction({
    url: 'admin/tenant-payments',
    method: 'POST',
    onFailure,
    data,
    onSuccess: callback,
    label: 'action',
  });
}
export function getSelectedFeatures({filters, onSuccess,onFailure }) {
  const query = filterQueryResolver(filters);
  const url = `/admin/tenant-features?${query}`;
  return apiAction({
      url: url,
      label: '',
      onSuccess: onSuccess,
      onFailure,
  });
}
export const getSubscribeUpdateId = id =>
  apiAction({
    url: `/admin/tenant-subscriptions/latest/${id}`,
    onSuccess: setSubscribeUpdateId,
    label: 'registryType',
  });

export function getSubscribeId(props = {}) {
  const { id, onFailureCallback, onSuccessCallback } = props;
  return apiAction({
    url: `admin/tenant-subscriptions/latest/${id}`,
    onSuccess: data => dispatch => {
      if (onSuccessCallback) dispatch(onSuccessCallback(data));
    },
    onFailure: error => dispatch => {
      if (onFailureCallback) dispatch(onFailureCallback(error));
    },
    label: 'getSubscribeId',
  });
}

export const getSubscribeUpdateIdFinance = id =>
  apiAction({
    url: `/admin/tenant-subscriptions/latest/${id}?isRequest=1`,
    onSuccess: setSubscribeUpdateIdFinance,
    label: 'registryType',
  });

export function getSubscribeIdFinance(props = {}) {
  const { id, onFailureCallback, onSuccessCallback } = props;
  return apiAction({
    url: `admin/tenant-subscriptions/latest/${id}?isRequest=1`,
    onSuccess: data => dispatch => {
      if (onSuccessCallback) dispatch(onSuccessCallback(data));
    },
    onFailure: error => dispatch => {
      if (onFailureCallback) dispatch(onFailureCallback(error));
    },
    label: 'getSubscribeUpdateIdFinance',
  });
}

export const fetchPaymentInvoiceId = id =>
  apiAction({
    url: `/admin/tenant-payments/${id}`,
    onSuccess: setInvoiceId,
    label: 'fetchPaymentInvoiceId',
  });

export function fetchInvoiceSearch({ filters, onSuccess, attribute = {} }) {
  const query = filterQueryResolver(filters);

  return apiAction({
    url: `/admin/tenants?${query}`,
    onSuccess,
    attribute,
    label: 'setSalesInvoiceSearch',
  });
}
